
import React,{useState,useEffect,useRef,useLayoutEffect, useCallback} from 'react'

import styles from './styles/home.module.scss'
import axios from 'axios';
import gsap,{Power2,Power3,Back} from 'gsap'
import { connect } from "react-redux"
import {useHistory} from 'react-router-dom'
import Globals from './Globals'
import { getContentPath, fetchCMSJSON } from './AssetManager'
import ScaleText from 'system/components/ScaleText'
import CircularProgressBar from 'system/components/ProgressCircle';
import MiniSearch from 'minisearch'
import LoginForm from './components/LoginForm'
import Settings from './components/Settings'
import postEvent from './components/UserEvent'
import ControllerServerComs from 'system/ControllerServerComs';
import {fetchNarratives} from "./AssetManager"
import {setNarratives} from 'store/actions'

// import ControllerSettings from './components/ControllerSettings';
import {ReactComponent as Logo} from 'assets/home/logo.svg'
import {ReactComponent as IconHamburger} from 'assets/icons/burger.svg';
import {ReactComponent as IconSearch} from 'assets/icons/search.svg'
import {ReactComponent as IconClose} from 'assets/icons/close.svg'
import {ReactComponent as IconCaretRight} from 'assets/icons/caret_right.svg'
import {ReactComponent as BackIcon} from 'assets/icons/arrow_left.svg'
import {ReactComponent as Question} from 'assets/icons/help.svg'
import {ReactComponent as IconSettings} from 'assets/icons/settings.svg'
import {ReactComponent as Download} from 'assets/icons/download_file.svg'
import {ReactComponent as CheckCircle} from 'assets/icons/check_circle.svg'
import {ReactComponent as DeleteCircle} from 'assets/icons/delete_circle.svg'
import {ReactComponent as Sync} from 'assets/icons/sync.svg'

// import axios from 'axios'
import {setUser} from 'store/actions'


const HomeVideo = React.memo(React.forwardRef((props,ref)=>{
  return (<video ref={ref} key={`home-vid-bg`} className={`fullscreen ${styles.backgroundVideo} constant`} muted loop autoPlay src={props.src} alt="" open={props.open}
   onLoadedMetadata={props.onLoadedMetadata} 
   id="home-bg-video" ></video>)

}))
const videoPath = getContentPath('core/home/bg.mp4?d='+((new Date()).getTime()),true)
    

const Home = (props)=>{

    let history = useHistory();

    const refControllerAppListener = useRef();
    const refServerAppListener = useRef();
    const refWindowListener = useRef();
    const col1 = useRef(null);
    const refContainer = useRef(null);
    const refVideo = useRef();
    const refSlogan = useRef(null);
    const refBGImage = useRef(null);
    const refQ = useRef()
    const isMounted = useRef(false)
    const refSearch = useRef(null)
    const refLogin = useRef(null)
    const refSettings = useRef(null)
    const miniSearch = useRef()
    const refMyPropertiesWrapper = useRef();
    const statusRef = useRef();

    const [render,setRender]=useState(0)
    const [status, setStatus] = useState(null)
    const [currentPath,setCurrentPath] = useState()
    const [menuOpen,setMenuOpen] = useState(false)
    const [pageState, setPageState] = useState(props.user?"menu":"login")
    const [showGetStarted, setShowGetStarted]= useState(localStorage.getItem('getStarted'))
    const [tick , setTick] = useState(false)
    const [menuTree, setMenuTree] = useState(Object.assign([],props.narratives))
    const [bgHighlight ,setBGHighlight]= useState(false)
    const [helpId, setHelpId] = useState(null);
    const [searchState, setSearchState] = useState(null)
    const [searchStateResults,setSearchStateResults] = useState(null)
    const [loadPage, setLoadPage] = useState(false);
    const [downloaded, setDownloaded] = useState(null);

    // const [downloaded, setDownloaded] = useState([]);
    
   
    

    useEffect(()=>{

        // console.log('mini search')
        miniSearch.current = new MiniSearch({fields: ['title', 'subheading', 'meta'],
        storeFields: ['title', 'subheading', 'id'], // fields to return with search results
        searchOptions:{
            boost:{title:2},
            fuzzy:0.2,
            prefix: true
        }
      })
    
    if (props.user) {
        props.narratives && miniSearch.current.addAll(props.narratives.filter(n=>{
            return props.user.narratives.includes(n.id);
        })) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[miniSearch])

    useEffect(() => {
        console.log("downloaded", downloaded)
    }, [downloaded])

    useEffect(() => {
        if (render > 0) {
            setLoadPage(false)
        }
    }, [render])


    useEffect(()=>{
        let tree;
        let lookup={}
        let narratives;
        if(props.user){
            props.user.narratives.forEach( l=>{lookup['n'+String(l)]=true})
        }
        narratives = [ ...props.narratives ]
        

        let array = downloaded;
        // let array =  JSON.parse(localStorage.getItem('expo.downloaded'))
    
        if (array && array.length > 0) {
            narratives.forEach((a, i) => {
                let obj = array.find(b => parseInt(b.id) === a.id)
                let modified;
                if (obj) {
                    modified = obj['modified']
                } else {
                    modified = null
                }
                narratives[i]['download_modified'] = modified
            })
        }
       
        setMenuTree(narratives)

        let id;
        if ((Globals.instance().serverApp)||(Globals.instance().controllerApp)) {
           id = Globals.instance().narrativeHelpController
        } else if (Globals.instance().presentationMode) {
           id = Globals.instance().narrativeHelpLargeTouch
        } else {
           id = Globals.instance().narrativeHelp
        }
        setHelpId(id);
       
        // console.log('helpID', id);
        
       console.log('props.narratives', props.narratives)

       if (Globals.instance().controllerApp) {

        // ControllerServerComs.instance().sendUDPFromControllerToServer('downloadModified', 'test');
 
        window.removeEventListener('controller-event',refControllerAppListener.current)
        refControllerAppListener.current=(evt)=>{
            let detail = evt.detail
            if(!detail)return
            console.log(detail.event,detail)
            if(detail.event ==='loadState'){
               if (detail.state === 'loading') {
                setStatus({'narrative': parseInt(detail.nid), 'status': '0%' })
                statusRef.current = {'narrative': parseInt(detail.nid), 'status': '0%' }
               }
               if (detail.state === 'complete') {
                setStatus({'narrative': parseInt(detail.nid), 'status': '100%' })
                statusRef.current = {'narrative': parseInt(detail.nid), 'status': '100%' }
               }
            }
            if(detail.event ==='downloaded'){
                console.log('downloaded RECD', detail.command)

            }
        }
        
        window.addEventListener('controller-event', refControllerAppListener.current)

        console.log('controller server-event listener started')

        }   


       if (Globals.instance().serverApp) {
           
            window.removeEventListener('server-event',refServerAppListener.current)
            refServerAppListener.current=(evt)=>{
                let detail = evt.detail
                if(!detail)return
                console.log(detail.event,detail.command)
                if(detail.event ==='sync-narrative'){
                    let obj = narratives.find(a => a.id === parseInt(detail.command))
                    downloadNarrative(detail.command, obj.modified)
                }
                if(detail.event ==='delete-narrative'){
                    deleteNarrative(detail.command)
                }
                if (detail.event === 'resync-app') {
                    Globals.instance().useLocal = false
                    history.push('/resync')
                }
            }

         window.addEventListener('server-event', refServerAppListener.current)
        }
  
        return () => {
            window.removeEventListener('server-event',refServerAppListener.current)
            window.removeEventListener('controller-event',refControllerAppListener.current)
            console.log('controller server-event listener stopped')
            
        }
           

    },[props.narratives, props.user, downloaded])


    useEffect(()=>{
      
        if(!props.user && pageState!=="login" )
        {
            setPageState("login")
            setBGHighlight(false)
            setMenuOpen(false)
            history.replace({pathname: '/home'})
            console.log('force login')
        }else{
        
            if(Globals.instance().serverApp) return

            

        if(props.location.pathname==='/home/myproperties/')
        {
            setMenuOpen(true)
            setPageState("myproperties")
            setBGHighlight(true)
            
        }
        else if(props.location.pathname==='/home/search/')
        {
            setMenuOpen(false)
            setBGHighlight(true)
            setPageState("search")
        }
        else if(props.location.pathname==='/home/settings/')
        {
            setMenuOpen(true)
            setBGHighlight(true)
            setPageState("settings")
        }
        else if( props.location.pathname==='/home' && pageState!=="login")
        {
            setPageState("menu")
        }
       
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.location,props.match])

    useEffect(()=>{
    //     console.log(bgHighlight)
    if(isMounted.current)
        if(refVideo.current){
            if(bgHighlight)
                refVideo.current.setAttribute('open','')
            else 
                refVideo.current.removeAttribute('open')
        }
    //     // if(bgHighlight){  gsap.to(refBGImage.current,{duration:0.5,opacity:0.4, filter:'blur(10px)'})}
    //     // else{ gsap.to(refBGImage.current,{duration:0.5,opacity:1, filter:'blur(0px)'})}
    },[bgHighlight])
    
    useEffect(()=>{
        // if(refSearch.current){ refSearch.current.value=searchState}
    },[searchState])

    useEffect(()=>{
        // if(pageState==="search")
        // refSearch.current.focus()
        if(pageState==="login")
        gsap.from(refLogin.current,{opacity:0,duration:1,delay:0.5})
        // if(pageState==="settings")
        // gsap.from(refSettings.current,{opacity:0,duration:1,delay:0.5})
        
    },[pageState])

    useEffect(()=>{
        
        if(pageState!=="login" ){
             if(!menuOpen){
                localStorage.setItem('getStarted',"false")
                gsap.killTweensOf(refQ.current)
                gsap.to(refQ.current,{duration:0.35,delay:0.3,opacity:1.0,scale:1.0, ease: Back.easeInOut})    
                
                if(refQ.current && refQ.current.nextElementSibling){
                    gsap.to(refQ.current.nextElementSibling,{duration:0.35,delay:0.35,opacity:1.0,scale:1.0, ease: Back.easeInOut})
                }
            }
        }
    },[menuOpen,pageState])

    useLayoutEffect(()=>{
       
        if(isMounted.current === false) { 
            console.log("unmounted");return
        }
        
        let c1 = col1.current.querySelector('.'+styles.column1Container)
        
        if( menuOpen ){
            gsap.killTweensOf(c1)
            gsap.to(col1.current,{duration:0.25,css:{height:Globals.instance().presentationMode?'84%':'100%'},onComplete:()=>{
                c1.style.display='block'
                if(pageState==="settings"){
                    if(refSettings.current){
                    refSettings.current.style.display='block'
                    c1.style.opacity=1;
                    gsap.to(refSettings.current,{duration:0.5,opacity:1,ease:Power3.easeInOut})
                    }
                }else{
                    animateMenuContainerItems(c1)
                }
                window.dispatchEvent(new CustomEvent('hide-initial-bg'))
            },ease:Power3.easeInOut})
            gsap.to(refSlogan.current,{duration:0.35,opacity:0,ease:Power3.easeInOut})

        } else {
            col1.current.querySelector('.'+styles.column1Container).style.display='block'
            gsap.to(c1 ,{duration:0.35,ease:Power3.easeInOut,opacity:0, onComplete:(c1Container,c,eleSlogan)=>{
                c.style.display='none'
                Array.from(c1Container.querySelectorAll('.'+styles.columnItem)).forEach((ele,i)=>{ele.style.opacity=0})
                let temp = window.getComputedStyle(c1Container).height
                c1Container.style.height='auto'
                if(pageState==="menu" && eleSlogan){
                    eleSlogan.style.display='block'
                    gsap.to(eleSlogan,{duration:0.5,delay:0.3,opacity:1,ease:Power3.easeInOut})
                }
                if(pageState==="search" && eleSlogan){
                    eleSlogan.style.display='none'
                    eleSlogan.style.opacity=0                    
                }
                
                let h=window.getComputedStyle(c1Container).height
                c1Container.style.height=temp
                gsap.to(c1Container,{duration:0.35,css:{height:h},ease:Power2.easeInOut})
                
            }
            ,onCompleteParams:[col1.current,c1,refSlogan.current]})
        }
       
    },[pageState,menuOpen,currentPath])

    useEffect(() => {
        
        if ((Globals.instance().controllerApp)&&(props.user && props.user.device_sn)) {
            if (Globals.instance().usbRequested === false) {
              
                Globals.instance().usbRequested = true;          
                ControllerServerComs.instance().sendUDPFromControllerToServer('assign-usb-device', props.user.device_sn)
                console.log('passing arduino sn:', props.user.device_sn)

                
            } 
        }
      
   
       
    }, [props.user])


    function updateDownloadCookie(){
        axios.get('/startup').then((results)=>{
            console.log("startup", results.data.downloaded)
            localStorage.setItem('expo.downloaded', JSON.parse(results.data.downloaded))
        })
    }

    useEffect(()=>{ 
        if (Globals.instance().controllerApp && Globals.instance().ios) {
            updateDownloadCookie();
        }
        let downloadedArray =  JSON.parse(localStorage.getItem('expo.downloaded'));

        if (!Globals.instance().controllerApp){
            if (downloadedArray) {
                Globals.instance().downloaded = downloadedArray;
            }
            
            setDownloaded(downloadedArray)
            console.log('global.instance().downloaded init', downloadedArray);
    
        }
      
        let c1 = col1.current.querySelector('.'+styles.column1Container)
        gsap.killTweensOf(c1)
        gsap.to(col1.current,{duration:0.25,css:{height:Globals.instance().presentationMode?'84%':'100%'},onComplete:()=>{
            c1.style.display='block'
            if(pageState==="settings"){
                if(refSettings.current){
                refSettings.current.style.display='block'
                c1.style.opacity=1;
                gsap.to(refSettings.current,{duration:0.5,opacity:1,ease:Power3.easeInOut})
                }
            }else{
                animateMenuContainerItems(c1)
            }
            window.dispatchEvent(new CustomEvent('hide-initial-bg'))
        },ease:Power3.easeInOut})
        gsap.to(refSlogan.current,{duration:0.35,opacity:0,ease:Power3.easeInOut})
  

        isMounted.current=true
        Globals.instance().nid=null
        Globals.instance().trackPage('home/')

        function handleResize() {
            // Set window width/height to state
            if(!col1.current.hasAttribute("open")){
                col1.current.style.height='auto'
                let h=window.getComputedStyle(col1.current).height
                col1.current.style.height=h
            }
          }

        window.addEventListener("resize", handleResize);
        setTick(true)

      
        
        const onRenderHome=()=>{
            console.log('render-home')
            Globals.instance().updatebookmarks()

            setRender(1+Number(refContainer.current.getAttribute("render")))    
            
          }
          window.removeEventListener('render-home',onRenderHome)
          window.addEventListener('render-home',onRenderHome)
    
         
        return () => {
            isMounted.current = false
            window.removeEventListener("resize", handleResize)
            window.removeEventListener('render-home',onRenderHome)

        }
           
    
    },[])


    let  onClickTier = useCallback((narrative, size, modified, download_modified)=>{

        console.log('onClickTier', narrative, size, modified, download_modified)

        let path='/home'
        let index =narrative

        let showAlert = false;
        let updateRequired = false;
        // let downloadedArray = [];
        // let downloadCookie = localStorage.getItem('expo.downloaded')
        // if (downloadCookie) {
        //     downloadedArray = JSON.parse(downloadCookie)
        // }
        let downloadedArray = JSON.parse(localStorage.getItem('expo.downloaded'));
        if (downloadedArray && Globals.instance().controllerApp && !(downloadedArray.findIndex(a =>  parseInt(a.id) === narrative) !== -1)) {
            showAlert = true
        }
        if (download_modified && (modified > download_modified)) {
            updateRequired = true;
        }
        
        function syncPresentation() {
          
            ControllerServerComs.instance().sendUDPFromControllerToServer('sync-narrative', narrative);

              let notFoundAttempts=0
              //check if we are still syncing 
               const ping = ()=>{
                  console.log("ping")
                  axios.get('/status/'+narrative).then((results)=>{
                      console.log(results.data)
                      let state=results.data.state

                      switch (state) {
                        case "loading":
                            setTimeout( ping, 100)
                            break;
                        case "not found":
                            notFoundAttempts++
                            if(notFoundAttempts <= 4)
                                setTimeout( ping, 300)        
                            else
                                setStatus({'narrative': parseInt(narrative), 'status': 'error'})
                                statusRef.current = {'narrative': parseInt(narrative), 'status': 'error' }
                            break;
                        case "complete":
                            setStatus({'narrative': parseInt(narrative), 'status': true})
                            statusRef.current = {'narrative': parseInt(narrative), 'status': true}
                            setTimeout(() => {
                                goToPresentation() 
                            }, 300);
                            break;
                        default:
                            break;
                      }
                      
                  }).catch(function (error) {
                      
        
                    setStatus({'narrative': parseInt(narrative), 'status': error})
                    statusRef.current = {'narrative': parseInt(narrative), 'status': error}
      
                  })
              }
              setStatus({'narrative': parseInt(narrative), 'status': '0%'})
              statusRef.current = {'narrative': parseInt(narrative), 'status': '0%'}
              setTimeout(ping, 100)

        }

        function goToPresentation(){
            
            if(narrative){
                

                setLoadPage(true)
                Globals.instance().homeLink=history.location.pathname
                
                if(Globals.instance().controllerApp){
                    history.push('/controllernarrative/'+narrative)
                    console.log('controller click narrative')
                    return;
                }
                else if (Globals.instance().ios){
                    window.webkit.messageHandlers.notification.postMessage({ Object: 'present-narrative', narrative:narrative })
                    return
                }
    
                path=path+'/'+index
                setCurrentPath(path)
                history.push(path)
                let params = new URLSearchParams(props.history.location.search)
                params.set('narrative',narrative)
                gsap.delayedCall(0.25,(h,p,s)=>{h.push({pathname:p,search:s})},[history,props.history.location.pathname,params.toString()])
                
            }
            else if(currentPath !== path){
                path=path+'/'+index
                gsap.delayedCall(0.0,(h,p)=>{h.push(p)},[history,path])
                setCurrentPath(path)
            }        
            else{
                path=path+'/'+index
                history.push({pathname:path});
                setCurrentPath(path)
            }
            if(!menuOpen) setMenuOpen(true)
        }

        console.log('onClickTier 2', downloadedArray, narrative, updateRequired)

     
        if ((showAlert || updateRequired) && Globals.instance().controllerApp ) {
            if (updateRequired) {
                Globals.instance().showAlert('Update Required', "This presentation is " + size + "Mb. Do you want to continue?",true,syncPresentation)
            } else {
                Globals.instance().showAlert('Download Required', "This presentation is " + size + "Mb. Do you want to continue?",true, syncPresentation)
            }
            
        } else {
            if (Globals.instance().controllerApp && Globals.instance().ios) {
                syncPresentation()
            } else {
                goToPresentation()
            }
           
        }
 

        
        
       

    },[currentPath, history, menuOpen, props.history.location.pathname, props.history.location.search])
    
    let  animateMenuContainerItems=(container)=>{
        if(container){
            // let items = Array.from(container.querySelectorAll('.'+styles.columnItem))
            // container.style.display='block'
            
            // if(items.length>0){
            //     container.style.opacity=1
            // }else{
            //     container.style.opacity=0    
            // }
            // items.forEach((ele,i)=>{
            //     let opacity =1;
            //     if((ele.getAttribute('c')==='0' || ele.getAttribute('actives')==='0') && ele.getAttribute('narrative')===null){
            //         opacity=0.5;
            //         ele.style['pointer-events']='none'
            //     } else{
            //         ele.style['pointer-events']='all'
            //     }
            //     gsap.to(ele,{duration:0.35,opacity:opacity,ease:Power3.easeInOut,delay:(i+1)*.03})
            // }) 
        }
    }

    function onLogin(){ 
        setPageState("menu") 
        // Update this properly
        window.location.reload(false);
    }

    function logout(){
        props.setUser(null)
        setPageState("login")
        setBGHighlight(false)
        setMenuOpen(false)
        localStorage.removeItem('user')
        // history.push('/login')
        history.push(Globals.instance().getRoute('/login'));
        
        if(Globals.instance().electron){
            if(window.api && window.api.send) {
                window.api.send("toApp",  {"event":"reload-app"});
                console.log('reload clicked');
                return
            }
        } else {
            
            window.location.reload();
        }
    }
    
    function onClickSettings(){
        if(pageState!=="settings"){
        setPageState("settings")
        setBGHighlight(true)
        setMenuOpen(true)
        history.push('/home/settings/')
        }

    }

    async function onClickMenu(){
        // console.log("menumenu")
        setPageState("menu")
        // let c1 = col1.current.querySelector('.'+styles.column1Container)
            
        if(!menuOpen){
            setMenuOpen(true)
            setBGHighlight(true)
        }else{
            setBGHighlight(false)
            setMenuOpen(false)
        }
    }

    function onClickClose(){
        history.push(Globals.instance().getRoute('/home'))
        setBGHighlight(false)
        
    }

    async function onClickSearch(){ 
        
        gsap.to(refSlogan.current,{duration:0.25,opacity:0,
            onComplete:(eleSlogan)=>{
                eleSlogan.style.display='none'
            history.push('/home/search/')
        },onCompleteParams:[refSlogan.current]})

        // history.push('/home/search/')
    }
    function onClickMyProperties(evt){  
        history.push('/home/myproperties/')
        Globals.instance().homeLink=history.location.pathname
    }

    function onClickBookmark(evt){ 
        let narrative=evt.currentTarget.getAttribute('narrative')
        Globals.instance().homeLink=history.location.pathname
        if (Globals.instance().ios){
            window.webkit.messageHandlers.notification.postMessage({ Object: 'present-narrative', narrative:narrative })
            return
        }
        let params = new URLSearchParams(props.history.location.search)
        params.set('narrative',narrative)
        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
    }
    function onChangeSearch(evt){
        console.log('onsearch', evt)
        
        let val= evt.target.value
        if (val) {
            setSearchState(val)
        }
      
        // console.log(props.user)
        let results = miniSearch.current.search(val)

        console.log('onChangeSearch', results)


        if (val === "") {
            setSearchStateResults(null)
        } else {
            let menuTreeResults = []

            results.forEach(a => {
            
                let obj = menuTree.find(b => b.id === a.id)
                console.log('obj', obj, menuTree, a)
                menuTreeResults.push(obj)
    
            })
            if (menuTreeResults) {
                setSearchStateResults(menuTreeResults)
            }
        }


    
        
        // if(document.activeElement!==refSearch.current)
        // refSearch.current.focus()
    }

    function clearSearch() {
        refSearch.current.value = ""
        setSearchState(null);
        setSearchStateResults(null)
    }
    function onLoadBG(){
        if(!refContainer.current.classList.contains('animin')){
            refContainer.current.classList.add('animin')
            gsap.to(refContainer.current,{duration:1,opacity:1, ease:Power3.easeInOut})
            gsap.from(col1.current,{duration:0.75, left:'-19%',opacity:0, delay:0.25,ease:Power3.easeInOut})
        }
        refBGImage.current.style.visibility='visible'
    }
    function onLoadBGVideo(evt){
        console.log('onLoadBGVideo')
        if(!refContainer.current.classList.contains('animin')){
            refContainer.current.classList.add('animin')
            gsap.to(refContainer.current,{duration:1,opacity:1, ease:Power3.easeInOut})
            gsap.from(col1.current,{duration:0.75, left:'-19%',opacity:0, delay:0.25,ease:Power3.easeInOut})
        }
        evt.target.style.visibility='visible'
        
        // POST USER EVENT
        // props.user && postEvent(props.user.username, {"event": "page-enter", "page": "home", "page_title": "home", "page_id": "0", "search": ""}, props.user.market, props.user.category);
        // console.log('props.user', props.user)

  
    }
    function onClickSearchResult(evt){
        Globals.instance().homeLink=history.location.pathname
        let params = new URLSearchParams(props.history.location.search)
        let narrative =evt.currentTarget.getAttribute("narrative")
        
        if(Globals.instance().controllerApp){
             history.push('/controllernarrative/'+narrative)
                return;
        } else if (Globals.instance().ios){
            window.webkit.messageHandlers.notification.postMessage({ Object: 'present-narrative', narrative:narrative })
            return
        }
        params.set('narrative',narrative)
        gsap.delayedCall(0.25,(h,p,s)=>{h.push({pathname:p,search:s})},[history,props.history.location.pathname,params.toString()])
    }

    function onClickQuestion(evt){
        console.log('question')
        // if (Globals.instance().controllerApp) {
        //     history.push('/controllernarrative/'+helpId)
        //     ControllerServerComs.instance().sendUDPFromControllerToServer('navigate','/narrative/'+helpId)  
        //     return;
        // } else {
        //     let params = new URLSearchParams(props.history.location.search)
        //     let narrative = evt.currentTarget.getAttribute("narrative")
        //     if (Globals.instance().ios){
        //         window.webkit.messageHandlers.notification.postMessage({ Object: 'present-narrative', narrative:narrative })
        //         return
        //     } 
        //     params.set('narrative',narrative)
        //     gsap.delayedCall(0.25,(h,p,s)=>{h.push({pathname:p,search:s})},[history,props.history.location.pathname,params.toString()])
        // }
        
    }

    function getBookmark(val,index){
        let obj = props.narratives.find(o=>{ return o.id===val})
        // console.log(val,props.narratives)
        if(obj) return (                                            
         <div key={`book-rsul${val}${index}`} className={`${styles.searchResult}`} narrative={val} onClick={onClickBookmark}>{  ((v)=>{ 
                                            return obj?obj.title:''
                                         })(val)}</div>)
        else return
    }

    function quit() {
        if(Globals.instance().electron){
            if(window.api && window.api.send) {
                window.api.send("toApp",  {"event":"quit-app"});
                console.log('quit clicked');
                return
            }
            
        }
    }

    function reload() {
        if(Globals.instance().electron){
            if(window.api && window.api.send) {
                window.api.send("toApp",  {"event":"reload-app"});
                console.log('reload clicked');
                return
            }
        }
    }

    function onControllerReload(){
        console.log('onControllerReload')
        ControllerServerComs.instance().sendUDPFromControllerToServer('resync-app');
    }

    function deleteNarrative(id) {
        console.log('deleteNarrative', id)

        if(Globals.instance().electron){
            if(window.api && window.api.send) {
              window.api.send("toApp", {"event":"delete-narrative","narrative":id});
            }
        }

        if (Globals.instance().controllerApp) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('delete-narrative', id);
        }

        if (Globals.instance().ios){
            window.webkit.messageHandlers.notification.postMessage({ Object: 'delete-narrative', narrative:id })
            // finishDelete(id)
        }


        function finishDelete(id){
            let narratives = [ ...props.narratives ]
            let array = downloaded;
            // let array =  JSON.parse(localStorage.getItem('expo.downloaded'))
            let existing = [ ...array ]
            let index = existing.findIndex(a => parseInt(a.id) === parseInt(id))

            console.log('delete index, props.narratives')

            if (index > -1) {
                props.narratives.forEach((a, i) => {
                    // let obj = existing.find(b => parseInt(b.id) === a.id)
                    if (a.id === existing[index].id) {
                        narratives[i]['download_modified'] = null
                    }
                    
                })
                existing.splice(index, 1)
            
            }

            localStorage.setItem('expo.downloaded', JSON.stringify(existing))
            if (existing) {
                Globals.instance().downloaded = existing;
            }
            setDownloaded(existing);

            console.log('Globals downloaded updated on delete', Globals.instance().downloaded)            
            console.log('narratives on delete', narratives)
            setMenuTree(narratives);
            setStatus(null);
            statusRef.current = null;
        }
       
    
        refWindowListener.current=(evt)=>{
            let detail = evt.detail
            if(!detail)return

            if(detail.message==="Complete"){
                console.log('deleted')

                finishDelete(id)
            
            }
        }
         window.addEventListener('window-event',refWindowListener.current)


        
        
    }

    function downloadNarrative(id, modified) {
       
        console.log('downloadNarrative', id)

        

        if(Globals.instance().electron){
            if(window.api && window.api.send) {
              window.api.send("toApp", {"event":"sync-narrative","narrative":id, "modified": modified});
            }
        }

        if (Globals.instance().controllerApp) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('sync-narrative', id);
        }

        if (Globals.instance().ios){
            window.webkit.messageHandlers.notification.postMessage({ Object: 'sync-narrative', narrative:id })
        }

        if(Globals.instance().serverApp)
            ControllerServerComs.instance().postMessageFromServerToElectron( "narrative-load-state",{"narrative":id,"state":"loading"}
        )


        setStatus({'narrative': parseInt(id), 'status': false})
        statusRef.current = {'narrative': parseInt(id), 'status': false}

        refWindowListener.current=(evt)=>{

            let detail = evt.detail
            if(!detail)return
            if(detail.narrative===id.toString()){
                setStatus({'narrative': parseInt(id), 'status': detail.message})
                statusRef.current = {'narrative': parseInt(id), 'status': detail.message}
                console.log('downloading', detail.message)
            }
            if(detail.message==="Complete"){
                setStatus({'narrative': parseInt(id), 'status': true})
                statusRef.current = {'narrative': parseInt(id), 'status': true}
                
                window.removeEventListener('window-event', refWindowListener.current)
                let array;
                let existing;
                let downloadedArray = downloaded;
                // let cookie = localStorage.getItem('expo.downloaded');
                let index = -1;
                if (downloadedArray && downloadedArray.length > 0) {
                    array = downloadedArray;
                    // array =  JSON.parse(localStorage.getItem('expo.downloaded'))
                    existing = [ ...array ]
                    index = existing.findIndex(a => a.id === id);
                    if (index > -1) {
                        existing[index] = {'id': id, 'modified': modified}
                    } else {
                        let nObj = {'id': id, 'modified': modified}
                        existing.push(nObj)
                    }
                } else {
                    existing = []
                    let nObj = {'id': id, 'modified': modified}
                    existing.push(nObj)
                }
                
                console.log('existing cookie', existing, index)
                if (existing) {
                    Globals.instance().downloaded = existing;
                }
             
                localStorage.setItem('expo.downloaded', JSON.stringify(existing))
                setDownloaded(existing)
                let narratives = [ ...props.narratives ]
                
                let nIndex = narratives.findIndex(a => a.id === parseInt(id))
                narratives[nIndex]['download_modified'] =  narratives[nIndex]['modified']
                setMenuTree(narratives)
          
                console.log('setMenuTree', narratives)

                if(Globals.instance().serverApp)
                    ControllerServerComs.instance().postMessageFromServerToElectron("narrative-load-state",{"narrative":parseInt(id),"state":"complete"})
            }
            if(detail.event==='sync-error'){
                console.log("Received Error",detail.message)

                if(Globals.instance().homeLink){
                    props.history.push(Globals.instance().homeLink)
                }else{
                    props.history.push(Globals.instance().getRoute('/home'))
                }
                Globals.instance().showAlert('Connection Error',detail.message)

                if(Globals.instance().serverApp)
                    ControllerServerComs.instance().postMessageFromServerToElectron("narrative-load-state",{"narrative":parseInt(id),"state":"error"})
            }
        }
        window.addEventListener('window-event',refWindowListener.current)
    }

    const narrativeBlock = (narrative, index) => (
        <div 
            className={`${styles.columnItem}`} 
            key={`nav1 ${narrative.id} ${narrative.title} ${index}`} 
            index={index} 
            menu={narrative.title}  
            nid={narrative.id}
            size={narrative.size}
            narrative={narrative.id}
            c={1}
            
            > 
            {narrative.screenshot && <img onClick={() => onClickTier(narrative.id, narrative.size, narrative.modified, narrative.download_modified)} className={`${styles.columnThumb}`} src={getContentPath(narrative.screenshot)} alt="screenshot" />}
            
            <span className={styles.narrativeHeading}><h2 onClick={() => onClickTier(narrative.id, narrative.size, narrative.modified, narrative.download_modified)}>{narrative.subheading ? narrative.subheading : narrative.title}</h2>
            
            {((Globals.instance().electron || (Globals.instance().ios && !Globals.instance().controllerApp)) && !narrative.download_modified) &&
                <span className={`${styles.downloadIcons} group1`}>
                    {(statusRef.current && parseInt(statusRef.current.narrative) === narrative.id) ? 
                    ((statusRef.current.status === true) ? "" : <span className={`${styles.downloadIcon}`} style={{color: 'white'}}>{statusRef.current.status && statusRef.current.status !== "Downloading" && <CircularProgressBar percentage={parseInt(statusRef.current.status.replace('%',''))} strokeWidth={4} sqSize={Globals.instance().ios ? 30 : 40} />}</span>) : 
                        <span className={`${styles.downloadIcon}`}><Download onClick={() => downloadNarrative(narrative.id, narrative.modified)} /></span>
                    }
                </span>
            }
            {(((Globals.instance().electron || (Globals.instance().ios && !Globals.instance().controllerApp)) && narrative.download_modified )) &&
                <span className={`${styles.downloadIcons} group2`}>
                    {
                    (statusRef.current && parseInt(statusRef.current.narrative) === narrative.id) ? 
                    ((statusRef.current.status === true) ?
                     <CheckCircle className={`${styles.downloaded} ${styles.downloadIcon}`}  /> 
                     : 
                     <span className={`${styles.downloadIcon}`} style={{color: 'white'}}>{statusRef.current.status && statusRef.current.status !== "Downloading" && <CircularProgressBar percentage={parseInt(statusRef.current.status.replace('%',''))} strokeWidth={4} sqSize={40} />}</span>) 
                     : 
                        
                        (narrative.download_modified && (narrative.modified > narrative.download_modified)) ? 
                        
                        <span className={styles.updateBtn} onClick={() => downloadNarrative(narrative.id, narrative.modified)}>Update</span> : 
                        
                        <CheckCircle className={`${styles.downloaded} ${styles.downloadIcon}`}  />
                    
                    }
                    <DeleteCircle onClick={() => deleteNarrative(narrative.id)} className={`${styles.downloadIcon}`} />
                </span>
            }


            </span> 

        </div>
    )


    return( 
        
        <div className={`${styles.container} fullscreen ${statusRef.current && statusRef.current.status !== true ? styles.loading:""}`} ref={refContainer} path={currentPath} render={render}>

        <div className={styles.containerBox}>

            {loadPage && <span className={styles.loadingScreen} />}
                
                {/* <img className={`fullscreen ${styles.backgroundImage}`} alt="" src={getContentPath('core/home/bg.png',true)} open={bgHighlight ?true:false} ref={refBGImage} onLoad={onLoadBG} key={`home-bg-image`}></img> */}
                
                {tick && (
                    <HomeVideo key={`home-video`} src={videoPath} alt="" open={bgHighlight ?true:false} 
                    onLoadedMetadata={
                        (evt)=>{
                            // console.log("he")
                            onLoadBGVideo(evt)
                        }
                    } 
                    id="home-bg-video" ref={refVideo} />
                 )}

                <div ref={col1} className={`${styles.column} ${styles.column1} ${pageState}` } open={menuOpen?true:false}>
                    
                {pageState!=="search" && (
                    (props.user && props.user.org && props.user.org.logo) ? <img src={getContentPath(props.user.org.logo)} alt="logo" className={`${styles.logo} logo`} /> : <Logo alt="" className={`${styles.logo} logo`} />
                )}
                <div className={`${styles.searchContainer}`} >
                <IconSearch className={styles.searchIcon} />

                <div className={`${styles.searchFieldContainer}`}>

                    <input className={`${styles.searchInput}`} ref={refSearch} type="text" size="100" name="search" autoComplete="false" defaultValue="" onChange={(e) => onChangeSearch(e)} key={'searchinput'}></input>
                </div>
                {searchState && <IconClose onClick={() => clearSearch()} className={styles.searchCloseIcon} />}
                </div>
                
                <div className={styles.columnRow}>
                    <div className={`${styles.column1Container}`} >
                        {pageState === 'menu' &&<span className={`${styles.headingRow}`}>      
                        <h1>Spaces</h1> 

                        </span>}

                        <div className={`${styles.menuGrid} ${(menuTree && menuTree.length >= 4)?styles.largeGrid:""}`}>
                        {pageState === 'menu' && !searchStateResults && menuTree && menuTree.filter(a => a.status === "live").map((narrative,index)=>{
                    
                                return (
                                    narrativeBlock(narrative,index)
                                )
                        })}
                        {searchStateResults && searchStateResults.filter(a => a.status === "live").map((narrative,index)=>{
                     
                                return (
                                    
                                    narrativeBlock(narrative,index)
                                )
                        }) }
                        {searchStateResults && searchStateResults.length === 0 && searchState && <h2>No results found</h2>}
                 
                    
                        </div>
                    
                       { pageState === 'settings' && props.user && (<Settings user={props.user.username} userObj={props.user} logout={logout} ref={refSettings}></Settings>)
                        }

                    </div>
                    {/* <div className={`${styles.spacer}`}></div> */}
                    
                    
                    { 
                    pageState ==="login" && (<LoginForm ref={refLogin} onLogin={onLogin}></LoginForm>)
                    }
                    {
                    pageState !=="login" && (
                        <ScaleText ref={refSlogan}
                        key="home-logo"
                        containerStyle={{width:'77%'}}
                        className={`${styles.slogan}` }
                        viewBox={"0 0 283 110"} 
                        html={`Presentation`}
                        ></ScaleText>
                     )}

                </div>
                    
                
           
                {
                    pageState==="myproperties" && (
                        <div className={`${styles.myPropertiesContainer} hide-scroller`}>
                            <div className={`${styles.myPropertiesWrapper}`} ref={refMyPropertiesWrapper}>    
                            {
                                Globals.instance().updatebookmarks() || 
                                (Globals.instance().bookmarks && Globals.instance().bookmarks.map((val,index)=>{
                                    return (
                                        <React.Fragment> { getBookmark(val,index) }</React.Fragment>
                                    )
                                }))
                            }
                            </div>
                        </div>
                        )
                }
                
                </div>

               <div className={`buttonContainer ifServerHide`}>
               {
                props.user && (
                 <div style={{display: "none"}} className={`buttonContainerCol `}>
                  <div style={{display: "none"}} className={`round-btn`} onClick={onClickMenu}>
                    <IconHamburger></IconHamburger>
                   </div>
          
                    { (pageState==="search" ||  pageState==="myProperties") && (
                            <div className={`round-btn`} onClick={onClickClose}>
                                <IconClose></IconClose>
                            </div>
                        )}
                   </div>
                    )}

                    
                <div className={`buttonContainerCol `}>
                 {
                        props.user && (

                            <div className={`settings ${styles.logoutContainer}`}>
                                  { pageState!=="settings" && (<div className={`round-btn`} onClick={onClickSettings}><IconSettings></IconSettings></div>)}
                                  { pageState==="settings" && (<div className={`round-btn`} onClick={onClickClose}><IconClose></IconClose></div>)}
                                {/* <div className={`round-btn`} onClick={logout}>Logout</div> */}
                            </div>
                        )

                      
                    }
                    {Globals.instance().controllerApp && <div className={`settings ${styles.logoutContainer}`}>
                    <div className={`round-btn`} onClick={onControllerReload}><Sync></Sync></div>
                  {/* <div className={`round-btn`} onClick={logout}>Logout</div> */}
              </div>}
                    { pageState !== 'login' && 
               <div className={`${styles.helpContainer}`} open={menuOpen} style={{display: "none"}}>
                    
                    <div className={`round-btn`} narrative={helpId} onClick={onClickQuestion} ref={refQ}><Question></Question></div>
                    {showGetStarted==="true" && 
                    <div className={`${styles.helpCtaContainer}`}>
                        Click here for a quick<br></br>overview of the app
                        <div className={`${styles.helpCtaArrow}`}></div>
                    </div>
                    }
                </div>
                }

                </div>
               </div>
               
                <div className={styles.versionNote}> 
                   
                    { Globals.instance().dualOutput && <span>dual output</span>}
                    { Globals.instance().controllerApp && !Globals.instance().dualOutput && <span>controller app</span>}
                    {Globals.instance().serverApp && <span>controller mode</span>}
                </div>
            
            {Globals.instance().presentationMode && (
                <span>
                    <div className={`fcenter ${styles.presentationQuit}`} onClick={quit}> Quit </div>
                </span>
            )}

        </div>
    </div> 
    )
                
    
}


function mapDispatchToProps(dispatch) {
    return {setUser: val => dispatch(setUser(val))};
}
  
const mapStateToProps = state => {
    const {narratives,narrativeTree,user} = state
    return { narratives:narratives , narrativeTree:narrativeTree,user};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home)