import EventEmitter from 'events'
import store from '../store'

var Globals= (function(){
    var instance;

    class globals {

    constructor() {
        this.env = false
        this.format = 'web'
        this.contentpath = ''
        this.cmspath = ''
        this.version= "7.0.1"
        this.build="1.0.0"
        this.events= new EventEmitter()
        this.coreSynched = false
        this.initCanvas()
        this.isTouch= this.is_touch_device()
        this.basepath=''
        this.width=window.innerWidth
        this.height=window.innerHeight
        this.aspect_ratio = window.innerWidth/window.innerHeight
        this.ios=this.isIOS()
        this.ipad=this.isIpadOS()
        this.iosOverlay=false
        this.electron=false
        this.bookmarks=[]
        this.downloaded=[]
        this.nid=null
        this.isFullscreen = false
        this.narrativeHelp=178;
        this.narrativeHelpLargeTouch=251;
        this.narrativeHelpController=252;
        this.prefNavBox = true;
        this.appPath = "";
        this.useLocal = false;
        this.device_width = null;
        this.device_height = null;
        // this.adminAPI='https://present-api.udxd.co'
        // this.webURL='https://present.udxd.co'
        // this.adminOriginAPI="https://present-api.udxd.co"


        // this.adminAPI= 'https://cdn.expostudio.co'
        // this.webURL='https://www.expostudio.co'
        // this.adminOriginAPI="https://cdn.origin.expostudio.co"
      
        this.adminAPI= 'https://cdn.staging.expostudio.co'
        this.webURL='https://staging.expostudio.co'
        this.adminOriginAPI="https://cdn.staging.origin.expostudio.co"

        if(process.env.NODE_ENV==="development") {
          this.socketURL = "localhost:3001"
      
        } else {
          this.socketURL = "https://socket.expostudio.co"
    
        }


      
        // 

        var userAgent = navigator.userAgent;
        if (userAgent && userAgent.toLowerCase().indexOf('electron/') > -1) {
          this.electron=true
        }
        

       
        
        this.frmAnalytics= document.getElementById('analytics-frame')
        // console.log(this.frmAnalytics)
        
        this.serverIP = '';
        this.controllerPing = false;
        this.usbRequested = false;
        
        
    }

    init(){
        this.basepath=process.env.REACT_APP_BASE?process.env.REACT_APP_BASE:''
        this.mode = process.env.REACT_APP_BUILD_MODE?process.env.REACT_APP_BUILD_MODE: 'web';
        this.analyticsId=process.env.REACT_APP_GA?process.env.REACT_APP_GA:''
        this.useHashRouter = this.mode.indexOf('local') > -1
        this.usecms = this.mode.indexOf('cms') > -1
        
        this.controllerApp = process.env.REACT_APP_CTRL==="APPCONTROLLER"
        this.serverApp = process.env.REACT_APP_SERVER==="APPSERVER"
        this.presentationMode = process.env.REACT_APP_PRESENTATION==="PRESENTATION"
        this.dualOutput = process.env.REACT_APP_DUAL_OUTPUT==="DUAL"

        let p = process.env.REACT_APP_CONTENT
        this.loadedContentpath = (p ? p : '/' )
        this.contentpath = (p ? p : '/' )
        this.cmspath = this.adminAPI

        this.localCmsPath='./api/'
        this.localContentPath='./media/'


        if(this.mode!=='static-web')
          this.cmspath = this.cmspath +'/api/' ;//p ? p : '/'
        

        if(this.ios) this.mode='local'

        if(this.ios || this.serverApp){
          this.cmspath='./api/'
          this.contentpath='./media/'
          this.useHashRouter=true
          this.usecms=false

        }

        if (this.useLocal) {
          this.usecms = false
        }
        
        // if(this.ios || this.electron){
        //   this.cmspath='./api/'
        //   this.contentpath='./media/'
        //   this.useHashRouter=true
        //   this.usecms=false
        // }
        
        console.log("mode : "+this.mode)
        console.log("router : "+(this.useHashRouter?'hash':"browser"))
        console.log("cms : " + (this.usecms?'using cms':"using static"))
        console.log("cms path : "+this.cmspath)
        console.log("content path : " + this.contentpath)
        console.log("api path : " + this.adminAPI)

        let format = document.body.getAttribute('format')
        this.format = format ? format : 'web' 
        
        let env = document.body.getAttribute('env')
        this.env = env ? env : 'development' 
        
        this.version = process.env.REACT_APP_VERSION

        console.log('verison : '+ this.version +' '+this.env)
        console.log('format : '+this.format)

        if(this.format ==='web'){
          window.addEventListener('resize',()=>{
            let ele = document.getElementById('main')
             this.aspect_ratio = ele.clientWidth/ele.clientHeight
             
            //  console.log('aspect: '+this.aspect_ratio)
          },{passive:true})
        }

        if(this.ios)
          document.body.classList.add('ios')
        if(this.ipad)
          document.body.classList.add('ipad')
       
        this.updatebookmarks()
        if(this.electron){
          this.listenElectron()
          this.isFullscreen=true //electron apps open in fullscreen

        }        
        
        //CREATING IN WEBGL ISSUES
        if(this.ios){
          window.createImageBitmap = undefined
        }
        // window.createImageBitmap=undefined
        
        if(document.body.getAttribute("controllerApp")==="true" || (new URLSearchParams(window.location.search)).get("controllerApp")==="true"){
          this.controllerApp = true
        }
        if(document.body.getAttribute("serverApp")==="true" || (new URLSearchParams(window.location.search)).get("serverApp")==="true"){
          this.serverApp = true
        }
        if(document.body.getAttribute("presentationMode")==="true" || (new URLSearchParams(window.location.search)).get("presentationMode")==="true"){
          this.presentationMode = true
        }
        if((new URLSearchParams(window.location.search)).get("prefNavBox")==="false"){
          this.prefNavBox = false
        }
        if((new URLSearchParams(window.location.search)).get("iosOverlay")==="true"){
          this.iosOverlay=true
          document.body.style.backgroundColor='transparent'
          document.body.parentElement.style.backgroundColor='transparent'
          
        }
        let downloadCookie = localStorage.getItem('expo.downloaded')
        if (downloadCookie) {
          this.downloaded = JSON.parse(downloadCookie)
        }
        
        if(this.controllerApp) document.body.classList.add('controllerApp')
        if(this.serverApp) document.body.classList.add('serverApp')
        if(this.presentationMode) document.body.classList.add('presentationMode')
        if(!this.electron) document.body.classList.add('electronApp')
        // if(this.aspect_ratio) document.body.classList.add(this.aspect_ratio)

        console.log("CONTROLLER MODE : "+ this.controllerApp)
        console.log("SERVER MODE : "+ this.serverApp)
        console.log("PRESENTATION MODE : "+ this.presentationMode)

    }

    listenElectron(){
      console.log("listenElectron");
      window.api.receive("fromApp", (data) => {
        console.log("fromApp Received", data);
        let objFromApp =data[0]
        if(objFromApp.action === 'location'){
              window.location= 'file://'+objFromApp.message
              // console.log('window.location', 'file://'+objFromApp.message)
        }

     

        if(objFromApp.action === 'window-event'){
          console.log(`Received ${JSON.stringify(data)} from main process`);
          window.dispatchEvent(new CustomEvent('window-event',{detail:objFromApp.detail}))

            let detail = data.detail
            if(detail && detail.event==='set-fullscreen-on'){
              Globals.instance().isFullscreen=true
            }
            else if (detail && detail.event==='set-fullscreen-off'){
              Globals.instance().isFullscreen=false
            }
        }
        if(objFromApp.action === 'server-event'){
          let detail = data.detail
           if(detail && detail.event==='turn-on-serverApp'){
             Globals.instance().serverApp=true
          }

         
        }

 

    });
    
    }
    initCanvas(){
      //canvas
      this.eleCanvas = document.createElement('canvas');
      this.eleCanvas.style['z-index']=-10
      this.eleCanvas.style.visibility='hidden'
      document.body.appendChild(this.eleCanvas)
    }

    is_touch_device() {  
      try {  
        document.createEvent("TouchEvent");  
        return true;  
      } catch (e) {  
        return false;  
      }  
    }

    getCanvasFormat(arrFormats,pageFormat){
      let f =null
      if(pageFormat){
        f=arrFormats.find( f=>{return f.aspect_ratio===pageFormat.aspect_ratio})
      }
      // console.log(pageFormat.aspect_ratio,f)
     
      if(!f){
        f=arrFormats.find( f=>{return f.aspect_ratio==='16:9'})
      }
      if(!f && arrFormats.length){
        f=arrFormats[0]
      }
      
      
      return f
    }

    getFormat(arrFormats,fallback){
        let closest = Infinity
        let f = null
        //default 
        if(!arrFormats || arrFormats.length===0){
          if(fallback)return fallback
          return {
            "aspect_ratio":"16:9",
            "canvas_width":"1920px",
            "canvas_height":"1080px",
            "crop":false
          }
        }

        arrFormats.forEach((format)=>{
          let aspect_ratio = format['aspect-ratio']
          
          if(format['aspect_ratio']){ aspect_ratio=format['aspect_ratio']}
          
          // console.log("format['aspect_ratio']", format['aspect_ratio'], this.width, Math.abs(this.stringToAspect(aspect_ratio) - this.width))
          
          // console.log("TABLET CHECK", width >= parseInt(format['canvas_width'].replace('px', '')))
          let d = Math.abs(this.stringToAspect(aspect_ratio) - this.aspect_ratio)

          function validFormat() {
            let r = true;
            // console.log(format['max_canvas_width'], window.innerWidth)
            if (format['max_canvas_width']){
              if (window.innerWidth <= format['max_canvas_width']) {
                r = true;
              } else {
                r = false;
              }

            } 
            return r
          }

          // console.log('tablet check', validFormat(), format['aspect_ratio'], d)

          if(d<closest && validFormat()){
              closest = d
              f=format
          }

        })
        console.log('getFormat',f) 
        return f
    }

    stringToAspect(str){
      let aspect_ratio;
      aspect_ratio=Number(Number(str))/1
      return aspect_ratio
    }

    getRoute(route){
      // console.log('getRoute', route)
      return this.basepath+route
    }
    
    isIOS() {
      return !!( window.webkit && window.webkit.messageHandlers )
    }
    
    iosVersion(){
      let d, v;
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      d = {
        status: true,
        version: parseInt(v[1], 10) , 
        info: parseInt(v[1], 10)+'.'+parseInt(v[2], 10)+'.'+parseInt(v[3] || 0, 10)
      };
    }else{
      d = {status:false, version: false, info:''}
    }
    return d;
    }
    
    isIpadOS(){
      if(navigator.userAgent.toLowerCase().indexOf('ipad')>-1)return true
      let bIpad= navigator.maxTouchPoints &&
      navigator.maxTouchPoints >= 2 &&
      /MacIntel/.test(navigator.platform);
      
      return bIpad
    }

    getThemeStyle(arrProperties,theme){
      let s={}
      if(theme && theme.lookup){
        
        arrProperties.forEach(p=>{
          let obj = {}
          for( let prop in theme.lookup[p]){
            let pkey = prop
            if(prop.indexOf("-")>0){
                let arr = pkey.split('-')
                pkey=arr[0]+arr[1].charAt(0).toUpperCase() + arr[1].slice(1);
            }
            obj[pkey]=theme.lookup[p][prop]
          }
          s= Object.assign(s, obj)
        })
      }
    return s
    }
    
    trackEvent(action,args){
      if(process.env.NODE_ENV==="development")return
      if(!this.frmAnalytics)
        this.frmAnalytics= document.getElementById('analytics-frame')
      if(this.frmAnalytics)
      this.frmAnalytics.contentWindow.postMessage({
        type:'event',
        action: action,
        args:args,
        userId:store.getState().userName,
         send_to: 'G-'+this.analyticsId
      },'*')
    }
    trackPage(page){
      if(process.env.NODE_ENV==="development")return
        if(!this.frmAnalytics)
        this.frmAnalytics= document.getElementById('analytics-frame')
        if(this.frmAnalytics)
        this.frmAnalytics.contentWindow.postMessage({
          type:'page_view',
          page_title: page,
          userId:store.getState().userName,
          page_location: window.location.href,
          page_path: page,
          send_to: 'G-'+this.analyticsId
        },'*')
    }
    trackError(desc,fatal){
      if(process.env.NODE_ENV==="development")return
      if(!this.frmAnalytics)
      this.frmAnalytics= document.getElementById('analytics-frame')
      if(this.frmAnalytics)
      this.frmAnalytics.contentWindow.postMessage({
        type:'event',
        action: 'exception',
        userId:store.getState().userName,
        args:{desc:desc,fatal:fatal},
        send_to: 'G-'+this.analyticsId
      },'*')
    }

    showAlert(title,message,cancel,continueBtn){
      console.log('dispatch')
      window.dispatchEvent(new CustomEvent('alert',{detail:{title:title,message:message,cancel:cancel,continueBtn:continueBtn}}))
    }
    updatebookmarks(){
      // console.log('update bookmarks')
      let strBooks=localStorage.getItem('bookmarks')
      if(strBooks)this.bookmarks=JSON.parse(strBooks)
    }

    toggleFullscreen(){
      if(this.electron){
        window.api.send("toApp", {"event":"toggle-fullscreen"});
        return
      }
      
      if(!document.fullscreenElement){
        document.documentElement.requestFullscreen().then(()=>{this.isFullscreen=true}).catch(err => {
          console.log(err)
        })
      }else if (document.exitFullscreen) {
        document.exitFullscreen();
        this.isFullscreen=false
      }

    }
  }
    

    



  return {
    instance: function(){
      // check if instance is available
      if (!instance) {
        instance = new globals();
        instance.init()
        delete instance.constructor; // or set it to null
      }
      return instance;
    }
  };
})();


export default Globals